import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { GrUpload } from "react-icons/gr";
import { useState } from "react";
import axios from "axios";


UploadImg.propTypes = {};

function UploadImg({uid}) {
  const [image, setImage] = useState(null);

  const [res, setRes] = useState(null);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  useEffect(() => {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("uid", uid);

    axios
      .post("https://media.digitalauto.tech/upload/image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => setRes(res))
      .catch((err) => console.log(err));
  }, [image]);

  return (
    <div className="mt-[20px]">
      <h3 className="text-3xl">Upload Image</h3>
      <form
        className="mt-[20px] w-full h-[200px] border justify-center flex items-center flex-col rounded-md border-dashed border-slate-300"
        // onSubmit={handleImageSubmit}
      >
        <input
          type="file"
          id="file"
          className="w-[213px] opacity-1"
          name="image"
          onChange={handleImageChange}
        />
      </form>
      {res != null ? (
        <div className="mt-[20px] text-center">
          <span className="text-lg font-semibold">Link url: </span>
          <a href={res.data}>{res.data}</a>
          <button className="px-[10px] py-[5px] border ml-[10px]" onClick={() => navigator.clipboard.writeText(res.data)}>
            Copy
          </button>
          <img className="mt-[20px] max-w-screen-sm m-auto" src={res.data} />
        </div>
        
      ) : ""}
      
    </div>
  );
}

export default UploadImg;
