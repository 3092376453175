import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import "@reach/dialog/styles.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDropzone } from "react-dropzone";
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
} from "react-sortable-tree";
import "react-sortable-tree/style.css";
import { STATIC_HOST } from "../constant/config";
import { Dialog, DialogOverlay, DialogContent } from "@reach/dialog";
import "@reach/dialog/styles.css";

const Dropzone = ({ treeData, setTreeData, listAllFiles, setLoading, setDropzoneRes }) => {
  const [mounted, setMounted] = useState(true);
  const [hovered, setHovered] = useState(false);
  const [folders, setFolders] = useState([]);
  const [type, setType] = useState();


  const [uid, setUid] = useState(localStorage.getItem("uid"));

  useEffect(() => {
    if (!uid) {
      let tmpUid = uuidv4();
      setUid(tmpUid);
      localStorage.setItem("uid", tmpUid);
    }
  }, []);

  useEffect(() => {
    return () => {
      setMounted(false);
    };
  }, []);

  const handleDrop = (acceptedFiles) => {
    setLoading(true);
    const formData = new FormData();
    if (acceptedFiles[0].type.includes("image")) {
      //upload image api
      formData.append("image", acceptedFiles[0]);
      formData.append("uid", uid);
      axios
        .post(`${STATIC_HOST}upload/image`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setDropzoneRes(res);
          setLoading(false);
          listAllFiles();
        })
        .catch((err) => {
          setLoading(false);
          toast.error("Upload image failed", {
            draggablePercent: 60,
          });
          console.log(err);
        });
    } else {
      //upload file api
      formData.append("uploaded_file", acceptedFiles[0]);
      formData.append(
        "type",
        acceptedFiles[0].name.substr(acceptedFiles[0].name.lastIndexOf(".") + 1)
      );
      formData.append("uid", uid);

      axios
        .post(`${STATIC_HOST}upload/file`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setLoading(false);
          listAllFiles();
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    noClick: true,
    noKeyboard: true,
  });

  const { isDragActive: isDragActiveInside } = useDropzone({
    onDragEnter: () => setHovered(true),
    onDragLeave: () => setHovered(false),
  });

  const isDragActiveOutside = isDragActive || isDragActiveInside;

  return (
    <div
      {...getRootProps()}
      style={{
        height: "90px",
        width: "992px",
        margin: "auto",
        marginBottom: "10px",
        // position: "fixed",
        // top: "0",
        // left: "0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: `2px dashed ${isDragActiveOutside ? "#888" : "black"}`,
        backgroundColor: isDragActiveOutside || hovered ? "#eee" : "#e5e7eb",
        outline: "none",
        userSelect: "none",
        // visibility: `${isDragActiveOutside ? "visible" : "hidden"}`,
        // opacity: `${isDragActiveOutside ? "1" : "0"}`,
        // pointerEvents: `${isDragActiveOutside ? "auto" : "none"}`,
      }}
    >
      <p>Drop new file here</p>
      <input {...getInputProps()} />
      <SortableTree treeData={treeData} onChange={setTreeData} />
    </div>
  );
};

export default Dropzone;
