import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useEffect } from "react";
import { useState } from "react";

export default function PaginationButtons({
  totalItems,
  onPageChange,
  currentPage,
  setCurrentPage,
}) {
  const [currentItems, setCurrentItems] = useState([]);
  const itemsPerPage = 8;
  const pageCount = Math.ceil(totalItems.length / itemsPerPage);
  const itemOffset = currentPage * itemsPerPage;
  // const currentItems = totalItems.slice(itemOffset, itemOffset + itemsPerPage);

  useEffect(() => {
    const newCurrentItems = totalItems.slice(
      itemOffset,
      itemOffset + itemsPerPage
    );
    setCurrentItems(newCurrentItems);
    // Call the callback function to pass the currentItems to the parent
    onPageChange(newCurrentItems);
  }, [currentPage, totalItems]);

  //   const handlePageClick = (selectedPage) => {
  //     setCurrentPage(selectedPage.selected);
  //   };

  const handlePageClick = (value) => {
    setCurrentPage(value - 1);
  };

  return (
    <div>
      {pageCount > 1 && (
        <Stack spacing={2}>
          <Pagination
            count={pageCount}
            shape="rounded"
            // onChange={handlePageClick}
            onChange={(event, value) => {
              handlePageClick(value);
            }}
            color="primary"
            showFirstButton
            showLastButton
          />
        </Stack>
      )}
    </div>
  );
}
