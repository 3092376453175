import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Dropdown from "../components/Dropdown";
import { type } from "@testing-library/user-event/dist/type";
import { Dialog, DialogOverlay, DialogContent } from "@reach/dialog";
import "@reach/dialog/styles.css";
import { IoCloseCircleOutline } from "react-icons/io5";
import JSLogo from "../utils/images/JavaScript_Logo.png";
import CSSLogo from "../utils/images/logo-css.png";
import HTMLLogo from "../utils/images/htmlLogo.png";
import MP4Logo from "../utils/images/mp4Logo.png";
import textLogo from "../utils/images/txtLogo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { forwardRef, useImperativeHandle } from "react";
import "react-sortable-tree/style.css";
import { STATIC_HOST } from "../constant/config";
import { useParams } from "react-router-dom";
import Dropzone from "../components/Dropzone";
import { MdOutlineClear } from "react-icons/md";
import Paginate from "../components/Paginate";
import { useCallback } from "react";
import PaginationButtons from "../components/MUIPaginate";
import { current } from "@reduxjs/toolkit";

const MyFiles = forwardRef((prop, ref) => {
  const [treeData, setTreeData] = useState([]);
  const [file, setFile] = useState(null);
  const [checkedArray, setCheckedArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [dropzoneRes, setDropzoneRes] = useState({});

  //Get state from Dropdown
  const myRef = useRef();
  const test = myRef?.current?.myState[0];
  const [reload, setReload] = useState("");
  const [type, setType] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();
  const refFileImage = useRef();
  const refFile = useRef();

  const [files, setfiles] = useState([]);
  const [images, setImages] = useState([]);
  const [image, setImage] = useState([]);
  const [duplicateImg, setDuplicateImage] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);

  const location = useLocation();

  const handlePageChange = useCallback((newCurrentItems) => {
    setCurrentItems(newCurrentItems);
  }, []);

  //Call useEffect(checkedArray) when reload
  useEffect(() => {
    setReload("New Value");
  }, []);

  //Set value for checkArray when reload
  useEffect(() => {
    setCheckedArray(test?.slice(0, test.length));
  }, [reload]);

  const userId = useParams();
  const [uid, setUid] = useState(
    Object.keys(userId).length > 0 ? userId.uid : localStorage.getItem("uid")
  );

  useEffect(() => {
    if (!uid) {
      let tmpUid = uuidv4();
      setUid(tmpUid);
      localStorage.setItem("uid", tmpUid);
    }
    listAllFiles();
    // listAllImages();
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setLoading(true);
  };

  const handleFilterChange = (checkedArray) => {
    setCheckedArray(checkedArray);
  };

  const triggerUploadImage = async () => {
    refFileImage.current.click();
  };

  const triggerUploadFile = async () => {
    refFile.current.click();
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    setLoading(true);
  };
  const [currentPage, setCurrentPage] = useState(0);

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm);

    const filtered = files.filter((file) =>
      file.originalname.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredFiles(filtered);
    setCurrentPage(0);
  };

  useImperativeHandle(ref, () => ({
    listAllFiles,
  }));

  const listAllFiles = async () => {
    try {
      const response = await axios.get(`${STATIC_HOST}file/all/${uid}`);
      const sortedFiles = response.data.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB - dateA;
      });
      setfiles(sortedFiles);
      setFilteredFiles(sortedFiles);
    } catch (error) {
      console.log(error);
      toast.error("Loading files failed", {
        draggablePercent: 60,
      });
    }
  };

  const duplicateFiles = files.map((file) => file.url);
  //call uploadImage api
  useEffect(() => {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("uid", uid);
    image?.length != 0 &&
      axios
        .post(`${STATIC_HOST}upload/image`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setLoading(false);
          setDuplicateImage(res.data.url);
          (duplicateFiles.includes(res.data.url) || res.data.duplicated) &&
            toast.warn("Image is already existed", {
              draggablePercent: 60,
            });
          listAllFiles();
        })
        .catch((err) => {
          toast.error("Upload image failed", {
            draggablePercent: 60,
          });
          setLoading(false);
          console.log(err);
        });
  }, [image]);

  //call uploadFile api
  useEffect(() => {
    const formData = new FormData();
    if (file && file.name) {
      formData.append(
        "type",
        type === null ? file.name.substr(file.name.lastIndexOf(".") + 1) : null
      );

      formData.append("uid", uid);
      formData.append("uploaded_file", file);

      file?.length !== 0 &&
        axios
          .post(`${STATIC_HOST}upload/file`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            setLoading(false);
            listAllFiles();
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
    }
  }, [file]);

  const dropdownRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setSelectedImage(null);
    }
  };

  const handleClearInput = () => {
    setSearch(""); // Clear the search term
    setFilteredFiles(files); // Reset filtered files to the original list
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // format date
  function formatDate(dateString) {
    const dateObj = new Date(dateString);
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return dateObj.toLocaleString("en-US", options);
  }

  return (
    <div className="">
      <div className="flex mb-6 mt-6 select-none items-center">
        <div>
          <Link
            to="/editor/new-file"
            className="rounded text-white px-2 py-[5.5px] bg-emerald-500 hover:bg-emerald-400 cursor-pointer"
          >
            Create New Plugin
          </Link>
          <button
            onClick={triggerUploadImage}
            className="ml-2 rounded text-white px-2 py-1 bg-emerald-500 hover:bg-emerald-400 cursor-pointer"
          >
            Upload Image
          </button>
          <button
            onClick={triggerUploadFile}
            className="ml-2 rounded text-white px-2 py-1 bg-emerald-500 hover:bg-emerald-400 cursor-pointer"
          >
            Upload File
          </button>
        </div>
        <div className="grow flex justify-end gap-[20px]">
          <div className="relative">
            <input
              type="text"
              id="search"
              className="border h-10 rounded-md pl-3 w-60 border-slate-300 outline-none"
              placeholder="Search..."
              onChange={handleSearch}
              value={search}
            />

            {search != "" && (
              <MdOutlineClear
                className="cursor-pointer absolute top-3 right-2.5"
                onClick={handleClearInput}
              />
            )}
          </div>
          <div className="z-1">
            <Dropdown handleFilterChange={handleFilterChange} ref={myRef} />
          </div>
        </div>
      </div>

      <input
        style={{ display: "none" }}
        type="file"
        ref={refFileImage}
        className="w-[213px] opacity-1"
        name="image"
        onChange={handleImageChange}
      />

      <input
        style={{ display: "none" }}
        type="file"
        id="file"
        className="w-[213px]"
        name="file"
        ref={refFile}
        onChange={handleFileChange}
      />

      <ToastContainer
        autoClose={2500}
        draggablePercent={60}
        style={{ width: "250px", height: "100px", fontSize: "13px" }}
      />

      <Dropzone
        treeData={treeData}
        setTreeData={setTreeData}
        listAllFiles={listAllFiles}
        setLoading={setLoading}
        setDropzoneRes={setDropzoneRes}
      />

      {!filteredFiles ||
      filteredFiles.length === 0 ||
      checkedArray?.length === 0 ? (
        <div className="text-center text-slate-500 py-4 px-4 rounded bg-slate-200">
          There is no files
        </div>
      ) : (
        <div className="w-full">
          {currentItems.map((file) => {
            const shouldRenderFile =
              checkedArray?.length === 0 || checkedArray?.includes(file.type);
            if (!shouldRenderFile) {
              return null;
            }

            let logo;
            switch (file.type) {
              case "image":
                logo = file.url;
                break;
              case "js":
                logo = JSLogo;
                break;
              case "css":
                logo = CSSLogo;
                break;
              case "html":
                logo = HTMLLogo;
                break;
              case "mp4":
                logo = MP4Logo;
                break;
              case "md":
                logo = textLogo;
                break;
              case "txt":
                logo = textLogo;
                break;
              default:
                return null;
            }
            return (
              <div>
                <div
                  key={file.id}
                  className="px-4 py-2 bg-slate-200 my-1 w-full"
                >
                  <div className="w-full">
                    <div className="flex gap-[30px]">
                      {file.type === "image" ? (
                        <img
                          className="w-[60px] h-[60px] rounded-md cursor-pointer"
                          src={file.url}
                          alt={`this is image of ${file.originalname}`}
                          onClick={() => {
                            setSelectedImage(file.url);
                          }}
                        />
                      ) : file.type === "mp4" ? (
                        <img
                          src={logo}
                          className="w-[60px] h-[60px] rounded-md cursor-pointer"
                          onClick={() => {
                            if (file.type === "mp4") {
                              setSelectedImage(file.url);
                            }
                          }}
                        />
                      ) : (
                        <img
                          src={logo}
                          className="w-[60px] h-[60px] rounded-md cursor-pointer"
                          onClick={() => {
                            if (file.type === "mp4") {
                              setSelectedImage(file.url);
                            }
                            navigate("/editor/" + file.filename, {
                              state: {
                                file: file,
                              },
                            });
                          }}
                        />
                      )}
                      <div className="grow">
                        {file.type !== "image" && file.type !== "mp4" && (
                          <Link
                            to={"/editor/" + file.filename}
                            state={{
                              file: file,
                            }}
                            className="hover:underline grow font-medium"
                          >
                            {file.originalname}
                          </Link>
                        )}
                        {file.type === "image" && (
                          <div
                            className="font-medium hover:underline cursor-pointer"
                            onClick={() => setSelectedImage(file.url)}
                          >
                            {file.originalname}
                          </div>
                        )}
                        {file.type === "mp4" && (
                          <div
                            className="font-medium hover:underline cursor-pointer"
                            onClick={() => setSelectedImage(file.url)}
                          >
                            {file.originalname}
                          </div>
                        )}
                        <div className="text-sm">{formatDate(file.date)}</div>
                      </div>
                      {selectedImage && selectedImage === file.url && (
                        <div className="">
                          <Dialog className="relative" ref={dropdownRef}>
                            <div className="flex flex-col items-center">
                              <button
                                onClick={() => setSelectedImage(null)}
                                style={{
                                  position: "absolute",
                                  top: "5px",
                                  right: "5px",
                                }}
                              >
                                <IoCloseCircleOutline
                                  className="hover:text-red-500"
                                  size="25px"
                                />
                              </button>

                              <div className="flex items-center text-[14px] mb-[15px] ">
                                <div>{file.url}</div>
                                <div>
                                  <button
                                    className="px-[5px] ml-[10px] hover:underline text-emerald-900 font-bold"
                                    onClick={() => {
                                      if (!navigator || !navigator.clipboard)
                                        return;
                                      navigator.clipboard.writeText(file.url);
                                      toast.success("Copied!");
                                    }}
                                  >
                                    Copy Link
                                  </button>
                                </div>
                              </div>
                              {file.type === "image" ? (
                                <img
                                  src={file.url}
                                  alt={`this is image of ${file.originalname}`}
                                  className="w-[80%]"
                                />
                              ) : (
                                <video
                                  src={file.url}
                                  alt={`this is video of ${file.originalname}`}
                                  controls
                                  autoPlay
                                />
                              )}
                            </div>
                          </Dialog>
                        </div>
                      )}
                      <button
                        className="px-[5px] ml-[10px] text-sm hover:underline text-emerald-900 font-bold"
                        onClick={() => {
                          if (!navigator || !navigator.clipboard) return;
                          navigator.clipboard.writeText(file.url);
                          toast.success("Copied!");
                        }}
                      >
                        Copy Link
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {loading && (
        <Dialog>
          <div className="w-11 m-auto flex-center h-10">
            <div
              style={{ width: `35px`, height: `35px` }}
              className="animate-spin"
            >
              <div
                className="h-full w-full border-4 border-t-blue-500
     border-b-blue-700 rounded-[50%]"
              ></div>
            </div>
          </div>
          <div className="text-center mt-3">Uploading...</div>
        </Dialog>
      )}

      {duplicateImg?.length > 0 && (
        <Dialog className="relative">
          <div className="flex flex-col items-center">
            <button
              onClick={() => setDuplicateImage(null)}
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
              }}
            >
              <IoCloseCircleOutline
                className="hover:text-red-500"
                size="25px"
              />
            </button>

            <div className="flex items-center text-[14px] mb-[15px] ">
              <div>{duplicateImg}</div>
              <div>
                <button
                  className="px-[5px] ml-[10px] hover:underline text-emerald-900 font-bold"
                  onClick={() => {
                    if (!navigator || !navigator.clipboard) return;
                    navigator.clipboard.writeText(duplicateImg);
                    toast.success("Copied!");
                  }}
                >
                  Copy Link
                </button>
              </div>
            </div>

            <img
              src={duplicateImg}
              alt={`this is image of ${duplicateImg.originalname}`}
              className="w-[80%]"
            />
          </div>
        </Dialog>
      )}

      {Object.keys(dropzoneRes)?.length > 0 && (
        <Dialog className="relative">
          <div className="flex flex-col items-center">
            <button
              onClick={() => setDropzoneRes({})}
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
              }}
            >
              <IoCloseCircleOutline
                className="hover:text-red-500"
                size="25px"
              />
            </button>

            <div className="flex items-center text-[14px] mb-[15px] ">
              <div>{dropzoneRes.data.url}</div>
              <div>
                <button
                  className="px-[5px] ml-[10px] hover:underline text-emerald-900 font-bold"
                  onClick={() => {
                    if (!navigator || !navigator.clipboard) return;
                    navigator.clipboard.writeText(dropzoneRes.data.url);
                    toast.success("Copied!");
                  }}
                >
                  Copy Link
                </button>
              </div>
            </div>

            <img
              src={dropzoneRes?.data.url}
              alt={`this is image of ${duplicateImg?.originalname}`}
              className="w-[80%]"
            />
          </div>
        </Dialog>
      )}

      {/* <div
        className={`select-none absolute md:top-[107%] xl:top-[94%] left-1/2 translate-x-[-50%] ${
          filteredFiles.length === 0 && filteredFiles < 8 ? "hidden" : ""
        }`}
      >
        <Paginate
          totalItems={filteredFiles}
          itemsPerPage={8}
          onPageChange={handlePageChange}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div> */}

      <div
        className={`select-none absolute xl:top-[107%] 2xl:top-[95%] left-1/2 translate-x-[-50%] pb-[10px]${
          filteredFiles.length === 0 && filteredFiles < 8 ? "hidden" : ""
        }`}
      >
        <PaginationButtons
          totalItems={filteredFiles}
          onPageChange={handlePageChange}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
});

export default MyFiles;
