import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { GrUpload } from "react-icons/gr";
import { useState } from "react";
import axios from "axios";
import Editor, { DiffEditor, useMonaco, loader } from "@monaco-editor/react";
import { v4 as uuidv4 } from "uuid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { file } from "@babel/types";
import { STATIC_HOST } from "../constant/config";

PageEdit.propTypes = {};

function PageEdit() {
  let { id } = useParams();

  const [uid, setUid] = useState(localStorage.getItem("uid"));
  useEffect(() => {
    if (!uid) {
      let tmpUid = uuidv4();
      setUid(tmpUid);
      localStorage.setItem("uid", tmpUid);
    }
  }, []);

  const [filename, setFilename] = useState("");
  const [content, setContent] = useState("");
  const [fileType, setFileType] = useState("javascript");
  const [fileLink, setFileLink] = useState("");
  const [res, setRes] = useState(null);
  const [validFileId, setValidFileId] = useState("");
  const [language, setLanguage] = useState("javascript");
  const [originalname, setOriginalname] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [originalContent, setOriginalContent] = useState("");
  const [originalFilename, setOriginalfilename] = useState("");

  const location = useLocation();
  const navigation = location.state?.file;
  const [checkNavigation, setCheckNavigation] = useState(navigation);

  const navigate = useNavigate();

  const handleFilenameChange = (e) => {
    setFilename(e.target.value);
  };

  const handleContentChange = (e) => {
    setContent(e);
  };
  const handleFileLinkChange = (e) => {
    setFileLink(e.target.value);
  };

  const handleFilterChange = (value) => {
    setLanguage(value);
  };

  useEffect(() => {
    if (id == "0" || id == "new-file") {
      setCheckNavigation([]);
    } else {
      fetchFile(id);
    }
  }, [id]);

  const handleFileName = (fileName) => {
    switch (language) {
      case "js":
        return fileName + ".js";
      case "javascript":
        return fileName + ".js";
      case "css":
        return fileName + ".css";
      case "html":
        return fileName + ".html";
      case "python":
        return fileName + ".py";
      case "csv":
        return fileName + ".csv";
      default:
        return filename;
    }
  };

  const fetchFile = async (id) => {
    await axios
      .get(`${STATIC_HOST}file/${id}`)
      .then((res) => {
        setFilename(res.data.originalname);
        setOriginalname(res.data.originalname);
        setFileUrl(res.data.url);
        setFileType(res.data.type);
        setLanguage(res.data.type);
        setValidFileId(id);
      })
      .catch((err) => console.log(err));

    await axios
      .get(`${STATIC_HOST}data/files/${id}`) //get content of editor
      .then((res) => {
        setContent(res.data);
        setOriginalContent(res.data);
      })
      .catch(async (err) => {
        const res = await axios.get(
          `${STATIC_HOST}data/user_files/${uid}/${id}`
        );
        setContent(res.data);
        setOriginalContent(res.data);
      });
  };

  const compareContent = content?.localeCompare(originalContent);
  const compareFilename = filename?.localeCompare(originalname);

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, [compareContent, compareFilename]);

  document.onkeydown = (e) => {
    if (e.ctrlKey && e.key === "s") {
      e.preventDefault();
      handleFileEdit();
    }
  };

  const handleFileEdit = async (e) => {
    if (isSaving) return;

    setIsSaving(true);

    if (validFileId) {
      //update existed file
      if (filename != originalname) {
        (async () => {
          await Promise.all([
            axios
              .post(`${STATIC_HOST}file/${validFileId}`, {
                filename: filename.includes(".")
                  ? filename
                  : handleFileName(filename),
                uid: uid,
              })
              .then((res) => {
                setContent(content);
                setFilename(res.data.originalname);
                setOriginalname(res.data.originalname);
                setFileUrl(res.data.url);
                setValidFileId(res.data.filename);
                navigate(`/editor/${res.data.filename}`);
              })
              .catch((err) => console.log(err))
              .finally(() => {
                setIsSaving(false);
              }),
            axios
              .put(`${STATIC_HOST}file/${validFileId}`, {
                filepath: `${STATIC_HOST}data/file/` + validFileId,
                content: content,
                uid: uid,
              })
              .then((res) => {
                setRes(res);
                toast.success(res.data, {
                  draggablePercent: 60,
                });
              })
              .catch((err) => console.log(err))
              .finally(() => {
                setIsSaving(false);
              }),
          ]);
        })();
      } else {
        axios
          .put(`${STATIC_HOST}file/${validFileId}`, {
            filepath: `${STATIC_HOST}data/file/` + validFileId,
            content: content,
            uid: uid,
          })
          .then((res) => {
            toast.success(res.data, {
              draggablePercent: 60,
            });
          })
          .catch((err) => console.log(err))
          .finally(() => {
            setIsSaving(false);
          });
      }
    } else if (filename.length > 0) {
      //create a new file
      axios
        .post(`${STATIC_HOST}file`, {
          filename: filename.includes(".")
            ? filename.trim()
            : handleFileName(filename.trim()),
          content: content,
          type: fileType,
          uid: uid,
        })
        .then((res) => {
          setRes(res);
          navigate(`/editor/${res.data}`);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setIsSaving(false);
        });
    } else {
      alert("Please enter a filename");
      setIsSaving(false);
    }
  };
  return (
    <div className="mt-[10px]">
      <div>
        <div className="text-slate-400 text-sm font-bold">File Name</div>
        <div className="flex items-center">
          <div className="grow mr-4">
            <input
              className={`bg-white w-full border rounded-md placeholder:italic placeholder:text-slate-400 focus:outline-none ${
                checkNavigation === undefined
                  ? "opacity-50 cursor-not-allowed"
                  : "focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
              } py-2 pl-4 pr-3`}
              placeholder={validFileId ? "" : "Your file name"}
              onChange={handleFilenameChange}
              defaultValue={validFileId ? filename : ""}
              disabled={checkNavigation === undefined}
            ></input>
          </div>
          <div className="">
            <select
              className="border rounded-sm placeholder:italic placeholder:text-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 py-2 px-2"
              onChange={(e) => {
                setLanguage(e.target.value || "text");
                setFileType(e.target.value || fileType);
              }}
              value={
                fileType === "md" || fileType === "txt" ? "text" : fileType
              }
            >
              <option value="js">Javascript</option>
              <option value="html">HTML</option>
              <option value="css">CSS</option>
              <option value="python">Python</option>
              <option value="text">Plain Text</option>
              <option value="json">JSON</option>
              <option value="xml">XML</option>
              <option value="text">CSV</option>
            </select>
          </div>
          <div>
            <button
              className={`ml-4 text-lg border px-6 py-1 rounded-md ${
                compareContent != 0 || compareFilename != 0
                  ? "text-white bg-emerald-400 hover:bg-emerald-300"
                  : "bg-gray-300"
              }`}
              type="submit"
              onClick={handleFileEdit}
              disabled={
                isSaving || (compareContent === 0) & (compareFilename === 0)
              } // Disable the button when isSaving is true
            >
              {isSaving ? "Saving..." : "Save"}
            </button>
            <ToastContainer
              autoClose={2500}
              draggablePercent={60}
              style={{ width: "250px", height: "100px", fontSize: "13px" }}
            />
          </div>
        </div>
        {validFileId && (
          <div className="bg-slate-100 rounded flex items-center text-sm mt-1">
            <span className="font-semibold mx-4">Deploy url: </span>
            <a className="text-[14px] grow select-text" href={fileUrl}>
              {fileUrl}
            </a>
            <button
              className="px-[10px] py-[5px] border ml-[10px] text-emerald-900 font-bold"
              onClick={() => {
                if (!navigator || !navigator.clipboard) return;
                navigator.clipboard.writeText(fileUrl);
                toast.success("Copied!");
              }}
            >
              Copy Link
            </button>
          </div>
        )}

        <div className="py-2 border mt-2 rounded-md bg-white h-[calc(100vh-170px)]">
          <Editor
            className=""
            height="calc(100vh - 200px)"
            defaultLanguage="javascript"
            defaultValue="// Write your code here..."
            onChange={handleContentChange}
            language={
              language === "js"
                ? "javascript"
                : language === "md" || language === "txt"
                ? "text"
                : language
            }
            value={content}
            options={{ minimap: { enabled: false } }}
          />
        </div>
      </div>
    </div>
  );
}

export default PageEdit;
