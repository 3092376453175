import "./App.css";
import React, { useState, useEffect } from "react";
import UploadImg from "./components/UploadImg";
import UploadFile from "./components/UploadFile";
import PageEdit from "./pages/Editor";
import MyFiles from "./pages/MyFiles";
import { HiHome } from "react-icons/hi";
import {
  Route,
  Routes,
  Link,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  function handleClick() {
    location.pathname === "/" ? window.location.reload(false) : navigate("/");
  }

  // useEffect(() => {
   
  // }, [location.pathname]);

  return (
    <div>
      <div className="my-[10px] flex justify-center ">
        <div className="w-full max-w-[1024px] px-4 py-2 rounded">
          <div className="flex">
            <HiHome
              size="2.5rem"
              onClick={handleClick}
              className="cursor-pointer"
              color="#1C6269"
            />
            <h1
              className="text-3xl h-10 text-center font-bold m-auto cursor-pointer bg-gradient-to-r from-[#005072] to-[#AEBD38] bg-clip-text text-transparent"
              onClick={handleClick}
            >
              digital.auto Plugin Editor
            </h1>
          </div>

          <Routes>
            <Route path="/" element={<MyFiles />} />
            <Route path="/:uid" element={<MyFiles />} />
            <Route path="editor/:id" element={<PageEdit />} />
            <Route path="upload-image" element={<UploadImg />} />
            <Route path="upload-file" element={<UploadFile />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;
