import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { GrUpload } from "react-icons/gr";
import { useState } from "react";
import axios from "axios";

UploadFile.propTypes = {};

function UploadFile(props) {
  const [file, setFile] = useState(null);
  const [type, setType] = useState();
  const [res, setRes] = useState(null);


  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  useEffect(() => {
    const formData = new FormData();
    if (file && file.name) {
      setType(file.name.substr(file.name.lastIndexOf('.') + 1));
      formData.append("uploaded_file", file);
      formData.append("type", type);
  
      axios
        .post("https://media.digitalauto.tech/upload/file", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => setRes(res))
        .catch((err) => console.log(err));
    }
  }, [file, type]);


  
  // const handleFileSubmit = async (e) => {
  //   e.preventDefault();

  //   const formData = new FormData();
  //   formData.append("uploaded_file", file);
  //   formData.append("type", type);

  //   axios
  //     .post("https://media.digitalauto.tech/upload/file", formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     })
  //     .then((res) => setRes(res))
  //     .catch((err) => console.log(err));
  // };

  return (
    <div className="mt-[20px]">
      <h3 className="text-3xl">Upload File</h3>
      <form
        className="mt-[20px] w-full h-[200px] border flex justify-around items-center flex-col rounded-md border-dashed border-slate-300"
        // onSubmit={handleFileSubmit}
      >
        <input
          type="file"
          id="file"
          className="w-[213px]"
          name="file"
          onChange={handleFileChange}
        />
      </form>
      {res != null ? (
        <div className="mt-[20px] text-center">
          <span className="text-lg font-semibold">Link url: </span>
          <a href={res.data}>{res.data}</a>
          <button className="px-[10px] py-[5px] border ml-[10px]" onClick={() => navigator.clipboard.writeText(res.data)}>
            Copy
          </button>
        </div>
      ) : ""}
    </div>
  );
}

export default UploadFile;
