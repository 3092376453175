import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";

const Dropdown = React.forwardRef(({ handleFilterChange }, ref) => {
  const languages = [
    "All",
    "JS",
    "HTML",
    "CSS",
    "Image",
    "Mp4",
    "Csv",
    "md",
    "txt",
  ];

  const [checkedState, setCheckedState] = useState(
    new Array(languages.length).fill(true)
  );

  const [myState, setMyState] = useState([
    languages.map((language) => language?.toLowerCase()),
  ]);

  useImperativeHandle(
    ref,
    () => {
      return {
        myState,
      };
    },
    [myState]
  );

  const [isOpen, setIsOpen] = useState(false);
  // const [checkedArray, setCheckedArray] = useState([
  //   languages.map((language) => language.toLowerCase()),
  // ]);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  function useForceUpdate() {
    let [value, setState] = useState(true);
    return () => setState(!value);
}

const handleForceupdateMethod = useForceUpdate();

  useEffect(() => {
    if (checkedState[0] === false) {
      setCheckedState(checkedState => checkedState.fill(false));
      handleForceupdateMethod();
    } else {
      setCheckedState(checkedState => checkedState.fill(true));
      handleForceupdateMethod();
    }
  }, [checkedState[0]]);

  const handleOnChange = (position) => {
    let updatedCheckedState;
    if (position === 0) {
      // Clicked on "All" checkbox
      updatedCheckedState = checkedState.map(() => !checkedState[0]);
    } else {
      // Clicked on other checkboxes
      updatedCheckedState = [...checkedState];
      updatedCheckedState[position] = !updatedCheckedState[position];
    }
  
    setCheckedState(updatedCheckedState);
  
    const checkedArray = languages
      .filter((language, index) => updatedCheckedState[index])
      .map((language) => language.toLowerCase());
  
    handleFilterChange(checkedArray);
  };
  
  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <div>
        <button
          type="button"
          className="h-10 inline-flex justify-center items-center w-full pl-4 pr-[7px] py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 gap-[4px]"
          id="dropdown-menu"
          aria-haspopup="true"
          aria-expanded={isOpen}
          onClick={toggleDropdown}
        >
          Filter
          <IoMdArrowDropdown size="10px" />
        </button>
      </div>
      {isOpen && (
        <ul
          className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="dropdown-menu"
        >
          {languages.map((language, index) => {
            return (
              <li key={index}>
                <div className={`toppings-list-item ${index === 0 ? "border-b border-slate-500":""}`}>
                  <div className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">
                    <input
                      type="checkbox"
                      className="mr-[10px]"
                      id={`custom-checkbox-${index}`}
                      name={language.name}
                      value={language.name}
                      checked={checkedState[index]}
                      onChange={() => handleOnChange(index)}
                    />
                    <label htmlFor={`custom-checkbox-${index}`}>
                      {language}
                    </label>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
});

export default Dropdown;
